import Privacypolicy from "./pages/privacypolicy";
import logo from "./assets/budboxlogo.jpeg";

function App() {
  return (
    <div className="App">
      <div className="w-full flex justify-center bg-[#12191F] pt-6">
        <img className="w-40" src={logo} alt="" />
      </div>
      <Privacypolicy />
    </div>
  );
}

export default App;
