import React from "react";

const Privacypolicy = () => {
  return (
    <>
      <div className="bg-[#12191F] text-white">
        <div className="mainContainer">
          <h1 className="text-center  font-bold  text-[#4483F1] text-3xl pt-10 pb-8 md:text-5xl md:pt-20 ">
            Privacy Policy
          </h1>

          <p className=" text-lg flex flex-col gap-5 pb-10 text-center  md:text-2xl">
            This Privacy Policy describes how SilverLine System Integrators Pvt.
            Ltd collects, uses, and shares information in connection with your
            use of our mobile application Budbox 4.0.
          </p>

          <div>
            <div className=" pb-10 md:pb-16">
              <div className="flex flex-col gap-10">
                <div>
                  <h1 className=" hideScrollbar text-2xl mb-10 text-center text-[#4483F1] font-bold md:text-3xl">
                    1. Information We Collect:
                  </h1>
                  <ul className="ml-3 text-lg flex flex-col gap-5 list-disc md:text-xl">
                    <li>
                      1.1. Information You Provide
                      <p className="pl-3">
                        - Account Information: When you add customer, we collect
                        customer name, email address, and other relevant
                        information.
                      </p>
                    </li>
                    <li>
                      1.2. Information We Collect Automatically
                      <p className="pl-3">
                        - Device Information: We may collect information about
                        your device, including unique device identifiers.
                      </p>
                    </li>
                  </ul>
                </div>
                <div>
                  <h1 className="hideScrollbar text-2xl mb-10 text-center text-[#4483F1] font-bold md:text-3xl">
                    2. How We Use Your Information
                  </h1>
                  <ul className="ml-3 text-lg flex flex-col gap-5 list-disc md:text-xl">
                    <li>
                      We use the information we collect for various purposes,
                      including:
                      <p className="pl-3">
                        - Providing Services: To provide and maintain the App,
                        authenticate users, process transactions, and ensure the
                        App's functionality.
                      </p>
                      <p className="pl-3">
                        - Customer Support: To respond to your inquiries,
                        provide customer support, and resolve issues.
                      </p>
                    </li>
                  </ul>
                </div>
                <div>
                  <h1 className="hideScrollbar text-2xl mb-10 text-center text-[#4483F1] font-bold md:text-3xl">
                    3. Information Sharing:
                  </h1>
                  <ul className="ml-3 text-lg flex flex-col gap-5 list-disc md:text-xl">
                    <li>
                      We do not sell, trade, or otherwise transfer your personal
                      information to outside parties. However, we may share your
                      information with:
                      <p className="pl-3">
                        - Service Providers: Third-party service providers who
                        assist us in operating the App and providing services.
                      </p>
                      <p className="pl-3">
                        - Legal Compliance: When required by law or to protect
                        our rights, privacy, safety, or property.
                      </p>
                    </li>
                  </ul>
                </div>
                <div>
                  <h1 className="hideScrollbar text-2xl mb-10 text-center text-[#4483F1] font-bold md:text-3xl">
                    4. Security:
                  </h1>
                  <ul className="ml-3 text-lg flex flex-col gap-5 list-disc md:text-xl">
                    <li>
                      We take reasonable measures to protect the confidentiality
                      and security of your information. However, no method of
                      transmission over the internet or electronic storage is
                      completely secure.
                    </li>
                  </ul>
                </div>

                <div>
                  <h1 className="hideScrollbar text-2xl mb-10 text-center text-[#4483F1] font-bold md:text-3xl">
                    5. Changes to This Privacy Policy
                  </h1>
                  <ul className="ml-3 text-lg flex flex-col gap-5 list-disc md:text-xl">
                    <li>
                      We may update this Privacy Policy to reflect changes in
                      our practices or for other operational, legal, or
                      regulatory reasons. We encourage you to review this page
                      periodically for the latest information.
                    </li>
                  </ul>
                </div>

                <div>
                  <h1 className="hideScrollbar text-2xl mb-10 text-center text-[#4483F1] font-bold md:text-3xl">
                    6. Contact Us:
                  </h1>
                  <ul className="ml-3 text-lg flex flex-col gap-5 list-disc md:text-xl">
                    <li>
                      If you have any questions or concerns about this Privacy
                      Policy, please contact us at{" "}
                      <span className="text-[#4483F1]">
                        info@silverlinepos.com
                      </span>{" "}
                      .
                    </li>
                  </ul>
                </div>
                <p className="text-lg flex flex-col gap-5 pb-10 text-center mt-10 md:text-2xl">
                  By using our software, you acknowledge that you have read and
                  understood this Privacy Policy and agree to the collection,
                  use, and disclosure of your personal information as described
                  .
                </p>
              </div>
            </div>

            <div className="flex justify-center pb-20"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacypolicy;
